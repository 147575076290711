<template>
  <div class=" max-w-2xl mx-auto p-4">
    <div class=" border-b pb-6">
      <div>
      <div class="flex items-center mt-4">
        <img class=" w-5 mr-2" :src="receipt" alt="">
        <div class=" black95 font-600 text-base">提现</div>
      </div>
      <div class="bg-gray-300 border border-gray-400 rounded-sm p-2 grid grid-cols-2 gap-2 mt-4 pl-4">
        <div>金额</div>
          <div>{{OrderDetails.amount}} {{ OrderDetails.currency }}</div>
        <div>日期:</div>
        <div>{{stepTwoFormData.payment_date}} NZT</div>
      </div>
    </div>
    <div>
      <div class="flex items-center mt-4">
        <img class=" w-5 mr-2" :src="bookSrc" alt="">
        <div class=" black95 font-600 text-base">收款人信息</div>
      </div>
      <div class="bg-gray-300 border border-gray-400 rounded-sm p-2 grid grid-cols-2 gap-2 mt-4 pl-4">
        <div>账户类型:</div>
          <div>{{BeneficiaryDetails.beneficiary_type}}</div>
        <div>收款人名字:</div>
        <div>{{BeneficiaryDetails.bank_account_holder_name}}</div>
        <div>收款账号:</div>
        <div>{{BeneficiaryDetails.account_number}}</div>
        <div>BIC/SWIFT Code:</div>
        <div>{{BeneficiaryDetails.bic_swift}}</div>
      </div>
    </div>
    <!-- <div class="flex items-center mt-4">
        <img class=" w-5 mr-2" :src="concilSrc" alt="">
        <div class=" black95 font-600 text-base">Beneficiary Bank Details</div>
      </div>
      <div class=" grid grid-cols-2 gap-2 mt-4 pl-4">
        <div>Currency:</div>
        <div>beneficiaryFormData.currency</div>
      </div>
      <div>
      <div class="pl-4">Priority:</div>
        <div class=" grid grid-cols-2 gap-1 mt-2 px-12">
        <div>Beneficiary Type:</div>
        <div class=" capitalize">beneficiaryFormData.beneficiary_type</div>
        <div>Address:</div>
        <div>beneficiaryFormData.beneficiary_address</div>
        <div>City:</div>
        <div>beneficiaryFormData.beneficiary_city</div>
        <div>Beneficiary Country:</div>
        <div>beneficiaryFormData.beneficiary_country</div>
        <div>Name:</div>
        <div>beneficiaryFormData.bank_account_holder_name</div>
        <div>BIC/SWIFT:</div>
        <div>beneficiaryFormData.bic_swift</div>
        <div>Account Number:</div>
        <div> beneficiaryFormData.account_number </div>
      </div>
      </div> -->
  </div>
   <div class="flex justify-evenly pt-4  max-w-3xl">
      <a-button :loading="createBtnLoading" :disable="createBtnDisable" @click="handleStepBack" type="danger" >
        上一步
      </a-button>
      <a-button :loading="createBtnLoading" :disable="createBtnDisable" @click="handleCreatePayment" type="primary" >
        确认提现
      </a-button>
    </div>
  </div>
</template>

<script>
import rotateSrc from '@/assets/icon/rotate.png'
import moneyExchangeSrc from '@/assets/icon/money-exchange.png'
import concilSrc from '@/assets/icon/council.png'
import bookSrc from '@/assets/icon/book.png'
import receipt from '@/assets/icon/receipt.png'
import fileSrc from '@/assets/icon/file-text-fill.png'
export default {
  created () {
  },
  props: {
    BeneficiaryDetails: {
      type: Object,
      default: () => {
      }
    },
    OrderDetails: {
      type: Object,
      default: () => {
      }
    },
    stepTwoFormData: {
      type: Object,
      default: () => {
      }
    },
    createBtnLoading: {
      type: Boolean,
      default: false
    },
    createBtnDisable: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      rotateSrc,
      concilSrc,
      bookSrc,
      moneyExchangeSrc,
      fileSrc,
      receipt,
      creatBtnLoading: false,
      creatBtnDisabled: false
    }
  },
  methods: {
    handleStepBack () {
      this.$emit('handleStepBack')
    },
    handleCreatePayment () {
      this.$emit('handleCreatePayment')
    }
  },
  computed: {
  }

}
</script>

<style scoped>
.checkmark {
  display: inline-block;
  width: 30px;
  height: 30px;
  position: relative;
}

.checkmark::after {
  content: "";
  display: block;
  width: 12px;
  height: 20px;
  border: solid #000;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
  position: absolute;
  top: 10%;
  left: 50%;
}
.grid_layout{
  grid-template-columns: 1fr 1fr;
}
</style>
