<template>
  <div>
    <CcHeader title="提现"></CcHeader>
    <div class=" px-12 py-8 max-w-5xl mx-auto">
      <a-steps :current="current">
      <a-step v-for="item in steps" :key="item.title" :title="item.title" />
    </a-steps>
    </div>
    <CCWrapper>
      <div v-show="current == 0">
        <a-spin :spinning="dataLoding || fetchAmountLoading">
        <a-form-model
          id="ccPaymentForm"
          ref="ccPaymentForm"
          :rules="rule"
          :model="orderForm"
          layout="horizontal"
          :label-col="{ span: 6 }"
          :wrapper-col="{ span: 14 }"
          >
      <div class="pb-6 border-b">
        <a-form-model-item prop="currency" class="mt-4 black95 text-sm font-500" label="选择货币">
            <a-select
            size="default"
            show-search
            placeholder="Select Currency"
            option-filter-prop="children"
            style="width: 100%"
            :filter-option="true"
            :not-found-content="null"
            v-model="orderForm.currency"
            @select="handleCurrencySelect"
          >
          <a-select-option v-for="item in balance" :key="item.currency" :value="item.currency">
            {{ item.currency  }}
          </a-select-option>
          </a-select>
        </a-form-model-item>
        <div v-if="selected_currency">
          <!-- selected_account.currency  -->
          <a-row>
            <a-col :span='6'>
            </a-col>
            <a-col :span='14'>
              <div>账户余额: {{ maxSellAmount }}  {{selected_currency }}</div>
            </a-col>
          </a-row>
          <a-form-model-item prop="amount"  class="mt-4 black95 text-sm font-500" label="金额">
            <a-input-number
              v-model="orderForm.amount"
              :step="0.01"
              style="width: 100%"
              :precision="2"
              :max="maxSellAmount"
            />
          </a-form-model-item>
          <a-form-model-item prop="beneficiary_id" class="mt-4 black95 text-sm font-500" label="选择收款人">
            <a-select
              size="default"
              show-search
              placeholder="选择收款人"
              option-filter-prop="children"
              style="width: 100%"
              :filter-option="true"
              :not-found-content="null"
              v-model="orderForm.beneficiary_id"
              >
                <a-select-option v-for="item in filter_beneficiary_list" :key="item.id" :value="item.beneficiary_id">
                    <span class="flex justify-between items-center">
                      <div class="flex flex-col">
                        <span>货币:</span>
                        <span>{{ item.bank_account_holder_name }}</span>
                      </div>
                      <div class="flex flex-col">
                        <span class=" font-500">{{ item.currency }}</span>
                        <span>{{ item.account_number }}</span>
                      </div>
                    </span>
                </a-select-option>
              </a-select>
              <router-link tag="li" to="/user/beneficiary/add">
                <div class=" text-red-600" v-if="orderForm.currency && filter_beneficiary_list.length == 0"> 请添加对应币种({{ orderForm.currency }})的收款人</div>
                <a class=" underline"> + 添加收款人</a>
              </router-link>
          </a-form-model-item>
        </div>
        <div class="flex justify-evenly pt-4">
              <a-button @click="handleCancel" type="danger" >
                取消
            </a-button>
              <a-button :loading="createBtnLoading" :disable="createBtnDisable" @click="handleCreate" type="primary" >
                下一步
            </a-button>
            </div>
      </div>
        </a-form-model>
        </a-spin>
      </div>
      <PaymentStepTwo
      @StepTwoNext="StepTwoNext"
      @handleStepBack="handleStepBack"
      :BeneficiaryDetails="selected_beneficiary" v-if="current == 1">
      </PaymentStepTwo>
      <PaymentCheck
      :BeneficiaryDetails="selected_beneficiary"
      :OrderDetails="orderForm"
      :stepTwoFormData="stepTwoFormData"
      :createBtnLoading="createBtnLoading"
      :createBtnDisable="createBtnDisable"
      @handleStepBack="handleStepBack"
      @handleCreatePayment="handleCreatePayment"
      v-if="current == 2"
      ></PaymentCheck>
    </CCWrapper>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { getCCBeneficiariesList, CreateCCPaymentCreate, getCCRealAmount } from '@/api/common'
import { message } from 'ant-design-vue'
import CcHeader from '../CCcomponents/CcHeader.vue'
import CCWrapper from '../CCcomponents/CCWrapper.vue'
import PaymentStepTwo from './PaymentStepTwo.vue'
import PaymentCheck from './PaymentCheck.vue'

export default {
  components: {
    PaymentStepTwo,
    CcHeader,
    CCWrapper,
    PaymentCheck
  },
  created () {
    if (this.$route.query.currency) {
      this.selected_currency = this.$route.query.currency
      this.fetchSelectedCurrencyDetails(this.$route.query.currency)
      this.orderForm.currency = this.$route.query.currency
    }
    if (this.$route.query.beneficiary_id) {
      this.orderForm.beneficiary_id = this.$route.query.beneficiary_id
    }
    this.dataLoding = true
    this.all_beneficiary_list = []
    getCCBeneficiariesList({ customer_id: this.customer.id }).then(res => {
      this.all_beneficiary_list = res.beneficiaries
      this.dataLoding = false
    }).catch(error => {
      console.log(error)
      this.dataLoading = false
    })
  },
  data () {
    return {
      current: 0,
      fetchAmountLoading: false,
      maxSellAmount: 0,
      dataLoding: false,
      selected_currency: '',
      all_beneficiary_list: null,
      selected_beneficiary: null,
      stepTwoFormData: null,
      createBtnLoading: false,
      createBtnDisable: false,
      steps: [
        {
          title: '提现金额'
        },
        {
          title: '付款人'
        },
        {
          title: '检查'
        },
        {
          title: '验证'
        }
      ],
      orderForm: {
        amount: 0,
        currency: '',
        beneficiary_id: ''
      },
      rule: {
        amount: [
          { validator: this.validateAmount, trigger: 'blur' }
        ],
        beneficiary_id: [
          { required: true, message: '请选取收款人', trigger: 'blur' }
        ],
        currency: [
          { required: true, message: '请选择货币', trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    handleStepBack () {
      this.current -= 1
    },
    routeToBeneficiaryCreate () {
      this.$router.push('/user/beneficiary/add')
    },
    validateAmount (rule, value, callback) {
      if (value > 0) {
        callback()
      } else {
        callback(new Error('金额必须大于0'))
      }
    },
    handleCurrencySelect  (value) {
      if (value !== this.selected_currency) {
        this.fetchSelectedCurrencyDetails(value)
      }
    },
    fetchSelectedCurrencyDetails (value) {
      this.selected_currency = value
      this.orderForm.beneficiary_id = ''
      this.orderForm.amount = 0
      this.fetchAmountLoading = true
      getCCRealAmount({ customer_id: this.customer.id, currency: value }).then((res) => {
        if (res?.available_balance) {
          if (Number(res.available_balance) <= 0) {
            this.$message.error('余额不足')
            this.maxSellAmount = 0
            return
          }
          this.maxSellAmount = Number(res.available_balance)
        }
      }).finally(() => {
        this.fetchAmountLoading = false
      })
    },
    handleCancel (name) {
      this.$router.push('/user')
    },
    handleSubmit () {
      CreateCCPaymentCreate({ customer_id: this.customer.id, ...this.orderForm }).then(res => {
        this.createBtnLoading = false
        this.createBtnDisable = false
        this.$router.push('/user/payment-list')
        this.$message.success('创建成功')
      }).catch(error => {
        this.createBtnLoading = false
        this.createBtnDisable = false
        if (error?.data?.amount) {
          message.error(error.data.amount)
        }
      })
    },
    StepTwoNext (stepTwoFormData) {
      this.stepTwoFormData = stepTwoFormData
      this.current = 2
    },
    handleCreatePayment () {
      if (this.createBtnLoading || this.createBtnDisable) {
        return
      }
      this.createBtnLoading = true
      this.createBtnDisable = true
      CreateCCPaymentCreate({ customer_id: this.customer.id, ...this.orderForm, ...this.stepTwoFormData }).then(res => {
        this.createBtnLoading = false
        this.createBtnDisable = false
        this.$router.push('/user/payment-list')
        this.$message.success('创建成功')
      }).catch(error => {
        this.createBtnLoading = false
        this.createBtnDisable = false
        if (error?.data?.amount) {
          message.error(error.data.amount)
        }
      })
    },

    handleCreate () {
      if (this.createBtnLoading || this.createBtnDisable) {
        return
      }
      this.createBtnLoading = true
      this.createBtnDisable = true
      this.$refs.ccPaymentForm.validate(valid => {
        if (valid) {
          this.selected_beneficiary = this.all_beneficiary_list.find((item) => item.beneficiary_id === this.orderForm.beneficiary_id)
          // this.orderForm.beneficiary_id
          this.current = 1
          this.createBtnLoading = false
          this.createBtnDisable = false
        } else {
          this.createBtnLoading = false
          this.createBtnDisable = false
        }
      })
    }
  },
  computed: {
    filter_beneficiary_list () {
      if (!this.all_beneficiary_list) return []
      else if (!this.selected_currency) return []
      else if (this.all_beneficiary_list.find(item => item.currency === this.selected_currency) === undefined) {
        return []
      } else {
        return this.all_beneficiary_list.filter(item => item.currency === this.selected_currency)
      }
    },
    selected_account () {
      return this.balance.filter(item => item?.currency === this.selected_currency)[0]
    },
    ...mapState('cc', ['customer']),
    ...mapState('cc', ['balance'])
  }
}
</script>

<style scoped>

</style>
