<template>
  <div>
    <a-form-model
          id="paymentStepTwo"
          ref="paymentStepTwo"
          :model="paymentStepTwoForm"
          :rules="rule"
          layout="horizontal"
          >
      <div>
        <a-form-item class="black95 text-sm font-500 mt-4" prop="payment_date" label="提现日期" :disabled-date="disabledDate" >
          <a-date-picker
          v-model="paymentStepTwoForm.payment_date"
          format="YYYY-MM-DD"
          :disabled-date="disabledDate"
          style="width: 100%" />
          </a-form-item>
      </div>
      <div>Your payment should arrive on {{   formatCustomDate(getNextWorkingDay(new Date(paymentStepTwoForm.payment_date))) }}  as long as you have sufficient {{ BeneficiaryDetails.currency }} before 13:00 on {{ formatCustomDate(new Date()) }}
      </div>
      <div class="">
        <div class=" mt-4 bg-gray-300 border border-gray-400 rounded-sm p-2 grid grid-cols-2 gap-2">
          <div>账户类型:</div>
          <div>{{BeneficiaryDetails.beneficiary_type}}</div>
          <div>收款人名字</div>
          <div>{{BeneficiaryDetails.bank_account_holder_name}}</div>
          <div>收款账号:</div>
          <div>{{BeneficiaryDetails.account_number}}</div>
          <div>BIC/SWIFT Code:</div>
          <div>{{BeneficiaryDetails.bic_swift}}</div>
        </div>
      </div>
      <hr class=" border-gray-400">
      <div>
          <a-form-model-item class="black95 text-sm font-500 mt-4" label="付款是否是本人">
          <a-radio-group  v-model="paymentStepTwoForm.pay_by_self" name="radioGroup">
            <a-radio class=" text-base" :value="1">是</a-radio>
            <!-- <a-radio class=" text-sm" :value="0">否</a-radio> -->
          </a-radio-group>
        </a-form-model-item>
        <div v-if="!paymentStepTwoForm.pay_by_self">
        <a-form-model-item  class="black95 text-sm font-500 mt-4" label="付款人类型">
          <a-radio-group  v-model="paymentStepTwoForm.payer_entity_type" name="radioGroup">
            <a-radio class=" text-base" value="individual">Individual</a-radio>
            <a-radio class=" text-sm" value="company">Entity</a-radio>
          </a-radio-group>
        </a-form-model-item>
        <a-form-model-item prop="bank_country" class="mt-4 black95 text-sm font-500" label="银行所属国家">
          <a-select
            size="default"
            show-search
            option-filter-prop="children"
            style="width: 100%"
            :filter-option="true"
            :not-found-content="null"
            v-model="paymentStepTwoForm.bank_country"
          >
          <a-select-option v-for="item in ccCountryList" :key="item.fullname" :value="item.shortname">
          {{ item.fullname  }}
        </a-select-option>
          </a-select>
        </a-form-model-item>
          <div v-if="paymentStepTwoForm.payer_entity_type == 'individual'">
            <a-form-model-item prop="payer_first_name" class="mt-4 black95 text-sm font-500" label="名">
            <a-input v-model="paymentStepTwoForm.payer_first_name"
            class="" size="default" placeholder="" />
          </a-form-model-item>
          <a-form-model-item prop="payer_last_name" class="mt-4 black95 text-sm font-500" label="姓">
            <a-input v-model="paymentStepTwoForm.payer_last_name"
            class="" size="default" placeholder="" />
          </a-form-model-item>
          </div>
          <div v-else>
            <a-form-model-item prop="payer_company_name" class="mt-4 black95 text-sm font-500" label="公司名称">
            <a-input v-model="paymentStepTwoForm.payer_company_name"
            class="" size="default" placeholder="" />
          </a-form-model-item>
          </div>
        <a-form-item  class="mt-4 black95 text-sm font-500" prop="payer_birthday" :label="paymentStepTwoForm.payer_entity_type == 'individual'? '出生日期':'注册时间'"  >
          <a-date-picker
          v-model="paymentStepTwoForm.payer_birthday"
          format="YYYY-MM-DD"
          placeholder=""
          style="width: 100%" />
          </a-form-item>
        <a-form-model-item prop="payer_address" class="mt-4 black95 text-sm font-500" label="地址">
            <a-input v-model="paymentStepTwoForm.payer_address"
            class="" size="default" placeholder="" />
          </a-form-model-item>
          <a-form-model-item prop="payer_city" class="mt-4 black95 text-sm font-500" label="城市">
            <a-input v-model="paymentStepTwoForm.payer_city"
            class="" size="default" placeholder="" />
          </a-form-model-item>
          </div>
      </div>
    </a-form-model>
      <div class="flex justify-evenly pt-4">
          <a-button @click="handleStepBack" type="danger" >
            上一步
        </a-button>
          <a-button @click="handleStepTwoNext" type="primary" >
            下一步
        </a-button>
        </div>
  </div>
</template>

<script>
import moment from 'moment'
import ccCountryList from '@/assets/cc/ccCountry.json'

export default {
  created () {
    this.ccCountryList = ccCountryList
    this.uniqueCurrencies = this.getUniqueCurrencies()
  },
  data () {
    return {
      paymentStepTwoForm: {
        payment_date: moment().format('YYYY-MM-DD'),
        pay_by_self: 1,
        payer_entity_type: 'individual',
        payer_company_name: null,
        payer_city: '',
        payer_first_name: '',
        payer_address: '',
        payer_last_name: '',
        payer_birthday: moment('1990-01-01').format('YYYY-MM-DD')
      },
      rule: {
        payment_date: [
          { required: true, message: '请选择提现日期', trigger: 'blur' }
        ],
        bank_country: [
          { required: true, message: '请选择货币', trigger: 'blur' }
        ],
        payer_first_name: [
          { required: true, message: '请输入名', trigger: 'blur' }
        ],
        payer_last_name: [
          { required: true, message: '请输入姓', trigger: 'blur' }
        ],
        payer_company_name: [
          { required: true, message: '请输入公司名字', trigger: 'blur' }
        ],
        payer_birthday: [
          { required: true, message: '请选择时间', trigger: 'blur' }
        ],
        payer_address: [
          { required: true, message: '请输入地址', trigger: 'blur' }
        ],
        payer_city: [
          { required: true, message: '请输入城市', trigger: 'blur' }
        ]
      }
    }
  },
  props: {
    BeneficiaryDetails: {
      type: Object,
      default: () => {
      }
    }
  },
  components: {
  },
  methods: {
    handleStepBack () {
      this.$emit('handleStepBack')
    },
    getUniqueCurrencies () {
      const uniqueCurrenciesSet = new Set()
      return this.ccCountryList.filter(option => {
        if (!uniqueCurrenciesSet.has(option.currency)) {
          uniqueCurrenciesSet.add(option.currency)
          return true
        }
        return false
      })
    },
    disabledDate (current) {
      // 获取当前日期的星期几（0 表示星期日，1 表示星期一，以此类推）
      const dayOfWeek = current.day()
      // 禁用星期六（6）和星期日（0）
      return dayOfWeek === 6 || dayOfWeek === 0 || current < moment().startOf('day')

      // return current && current < moment().endOf('day')
    },
    getNextWorkingDay (date) {
      const dayOfWeek = date.getDay()

      // 如果是周五（5），那么下一个工作日是下周一（1）
      if (dayOfWeek === 5) {
        date.setDate(date.getDate() + 3)
      } else if (dayOfWeek === 6) { // 如果是周六（6），那么下一个工作日是下周一（1）
        date.setDate(date.getDate() + 2)
      } else {
        date.setDate(date.getDate() + 1)// 其他情况下，下一个工作日是明天
      }
      return date
    },
    handleStepTwoNext () {
      this.$refs.paymentStepTwo.validate(valid => {
        if (valid) {
          if (this.paymentStepTwoForm.payment_date instanceof moment) {
            this.paymentStepTwoForm.payment_date = this.paymentStepTwoForm.payment_date.format('YYYY-MM-DD')
          }
          if (this.paymentStepTwoForm.payer_birthday instanceof moment) {
            this.paymentStepTwoForm.payer_birthday = this.paymentStepTwoForm.payer_birthday.format('YYYY-MM-DD')
          }
          if (this.paymentStepTwoForm.pay_by_self) {
            this.$emit('StepTwoNext', {
              payment_date: this.paymentStepTwoForm.payment_date,
              pay_by_self: 1
            })
          } else {
            if (this.paymentStepTwoForm.payer_entity_type === 'individual') {
              this.$emit('StepTwoNext', {
                payment_date: this.paymentStepTwoForm.payment_date,
                pay_by_self: 0,
                payer_entity_type: this.paymentStepTwoForm.payer_entity_type,
                payer_first_name: this.paymentStepTwoForm.payer_first_name,
                payer_last_name: this.paymentStepTwoForm.payer_last_name,
                payer_birthday: this.paymentStepTwoForm.payer_birthday,
                payer_address: this.paymentStepTwoForm.payer_address,
                payer_city: this.paymentStepTwoForm.payer_city,
                bank_country: this.paymentStepTwoForm.bank_country
              })
            } else {
              this.$emit('StepTwoNext', {
                payment_date: this.paymentStepTwoForm.payment_date,
                pay_by_self: 0,
                payer_entity_type: this.paymentStepTwoForm.payer_entity_type,
                payer_company_name: this.paymentStepTwoForm.payer_company_name,
                payer_birthday: this.paymentStepTwoForm.payer_birthday,
                payer_address: this.paymentStepTwoForm.payer_address,
                payer_city: this.paymentStepTwoForm.payer_city,
                bank_country: this.paymentStepTwoForm.bank_country
              })
            }
          }
        } else {
        }
      })
    },
    formatCustomDate (date) {
      const months = [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
        'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
      ]
      const day = date.getDate()
      const month = months[date.getMonth()]
      const year = date.getFullYear()

      return `${day} ${month} ${year}`
    }
  }
}
</script>

<style scoped>

</style>
